import React from "react";
import { useTranslation } from "react-i18next";

import { BookMeeting } from "../../components/BookMeeting";
import { Container } from "../../components/Container";
import ListWithChecks from "../../components/ListWithChecks";
import { VideoPlayer } from "../../components/VideoPlayer";
import { getTranslationList } from "../../utils/common";

const VideoBlock: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  const videoDescriptionList = getTranslationList(t, "index.video.list");

  return (
    <Container {...rest}>
      <section>
        <article>
          <h2>{t("index.video.header")}</h2>
          <VideoPlayer />
          <ListWithChecks list={videoDescriptionList} />
          <BookMeeting commentFirst={true} />
        </article>
      </section>
    </Container>
  );
};

export default VideoBlock;
