import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import tw from "twin.macro";
import { styled } from "twin.macro";

import Check from "../../static/icons/check.svg";

interface Props {
  list: string[];
}

const ListItem = styled.li`
  ${tw`mb-4`}
  svg {
    ${tw`text-turquoise fill-current transition mr-4 mt-1 sm:mt--1 h-7 sm:h-8`}
  }
`;
const ListWithChecks: React.FC<Props> = ({ list, ...rest }) => {
  const { t } = useTranslation();

  return (
    <ul tw="text-left ml-2 sm:ml-14 mt-8" {...rest}>
      {list.map((item, i) => (
        <ListItem tw="flex" key={i}>
          <Check tw="flex-shrink-0" />
          {t(item)}
        </ListItem>
      ))}
    </ul>
  );
};

export default ListWithChecks;
