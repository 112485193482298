import React, { useState } from "react";
import YouTube from "react-youtube";
import tw from "twin.macro";

import LoadingButton from "../../static/icons/youtubeCircularPlayButton.svg";
import Play from "../../static/icons/youTubePlay.svg";
import VideoPlaceholder from "../../static/images/triangular-process.png";

export const VideoPlayer = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const isLoading = isVideoOpen && !isVideoReady;
  const openVideo = () => setIsVideoOpen(true);

  return (
    <div
      css={[
        tw`max-w-screen-sm m-auto relative border-none overflow-hidden flex justify-center bg-turquoise-dark`,
        `height: 360px`,
        isVideoReady && tw`block`
      ]}
    >
      {isVideoOpen && (
        <YouTube
          css={[isVideoReady ? tw`opacity-100` : tw`absolute opacity-0`]}
          videoId="saLHW7lDokk"
          opts={{
            width: "100%",
            height: "360px",
            playerVars: {
              controls: 1,
              modestbranding: 1,
              color: "white"
            }
          }}
          onReady={() => {
            setIsVideoReady(true);
          }}
        />
      )}
      {isVideoReady || (
        <button tw="hocus:outline-none" onClick={openVideo}>
          <img src={VideoPlaceholder} alt="Video Placeholder" style={{ height: "360px", backgroundColor: "#fff" }} />
          {isLoading ? (
            <LoadingButton
              css={[
                tw`fill-current text-red absolute w-16`,
                "top: calc(50% - 2rem)",
                "left: calc(50% - 2rem)",
                `filter: drop-shadow(0 0 6px rgba(0, 0, 0, .7))`,
                tw`animate-spin`
              ]}
            />
          ) : (
            <Play
              css={[
                tw`fill-current text-red absolute w-20`,
                "top: calc(50% - 2rem)",
                "left: calc(50% - 2rem)",
                `filter: drop-shadow(0 0 6px rgba(0, 0, 0, .7))`
              ]}
            />
          )}
        </button>
      )}
    </div>
  );
};
